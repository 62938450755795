<template>
  <b-container fluid>
    <iq-card >
      <template v-slot:headerTitle >
          <h4 class="card-title">importDB</h4>
      </template>
        <template v-slot:headerAction>
          <b-button variant="secondary" size="lg" id="show-btn" @click="createSala">Crear Sala</b-button>
          <b-button variant="secondary" size="lg" id="show-btn" @click="showModal">importDB</b-button>
          <b-button variant="secondary" size="lg" id="show-btn" @click="handleOk1">prueba</b-button>
        </template>
        <template>
          <b-modal no-close-on-backdrop ref="my-modal" size="xl" @hide="closeMyModalAnCleanData" hide-footer title="Addd a property">
              <div>
                <div class="card-style">
                  <b-progress :value="progress" variant="success"></b-progress>
                </div>
                <b-card v-if="current_step==1" class="card-style" title="DB">
                  <b-card-text>Add DB</b-card-text>
                  <template>
                    <b-container fluid>
                      <b-row>
                        <b-col sm="12">
                          <b-overlay :show="show" rounded="sm" @shown="onShown" @hidden="onHidden">
                            <iq-card>
                              <template v-slot:headerTitle>
                                <h4 class="card-title">Importation</h4>
                              </template>
                              <template v-slot:body>
                                <div class="app-container">
                                  <upload-excel-component v-if="hideExcel" class="mb-3" :on-success="handleSuccess" :before-upload="beforeUpload" />
                                  <user-load @hideExcelField="hideExcelField" @saveDataMap="saveDataMap" :headers="tableHeader" :dataUsers="tableData"></user-load>
                                </div>
                              </template>
                            </iq-card>
                            <template #overlay>
                              <div class="text-center">
                                <b-icon icon="stopwatch" font-scale="3" animation="cylon"></b-icon>
                                <p id="cancel-label">Please wait...</p>
                              </div>
                            </template>
                          </b-overlay>
                        </b-col>
                      </b-row>
                    </b-container>
                  </template>
                  <b-button class="float-right" variant="secondary" @click="onClickNext(2)" :disabled="disabledBotonNext">Next</b-button>
                </b-card>
                <b-card v-if="current_step==2" class="card-style" title="Properties">
                  <div v-if="!spinner">
                    <b-card-text >Add properties</b-card-text>
                    <ValidationObserver v-slot="{handleSubmit}">
                      <form @submit.prevent="handleSubmit(handleOk1)">
                      <b-form-group id="input-group-1" label="Una vez cargue el excel este proceso no lo podra realizar nuevamente, la informacion que esta ingresando sera oficialmente toda la informacion de la copropiedad con su respectivos propietarios y propiedades. " label-for="text2-input">
                          <ValidationProvider name="Message the confirm " rules="required" v-slot="{ errors }">
                            <b-form-input id="text2-input" type="text" v-model="properties.confirmation" placeholder="Ingrese la palabra CONFIRMO para aceptar el cargue de información. " :class="(errors.length > 0 ? 'is-invalid':'')"></b-form-input>
                            <div class="invalid-feedback">
                              <span>{{ errors[0] }}</span>
                            </div>
                          </ValidationProvider>
                      </b-form-group>
                        <b-button class="float-left" variant="primary" @click="onClickBack">Back</b-button>
                        <b-button class="float-right" variant="primary" @click="consulta">consulta</b-button>
                        <b-button class="float-right" variant="secondary" type="submit">Submit</b-button>
                      </form>
                    </ValidationObserver>
                  </div>
                  <div v-else>
                    <div class="">
                      <b-row>
                        <b-col cols="12">
                          <b-progress :value="value" :max="max" :variant="colorVariant" show-progress animated></b-progress>
                        </b-col>
                      </b-row>
                    </div>
                    <div>
                      <loader-process :message="message"></loader-process>
                    </div>
                  </div>
                </b-card>
              </div>
          </b-modal>
        </template>
    </iq-card>
  </b-container>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { core } from '../../config/pluginInit'
import { db, st } from '../../config/firebase'
import moment from 'moment'
import UploadExcelComponent from '../../../src/components/UploadExcel/index'
import LoaderProcess from '@/components/core/loader/LoaderProcess.vue'
// import { AgGridVue } from 'ag-grid-vue'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import UserLoad from '@/components/CoProperties/UserLoad'
// This is necesary for componenet el-table
import Element from 'element-ui'
import Vue from 'vue'
import axios from 'axios'
Vue.use(Element)
const ref = st.ref()

export default {
  name: 'importDB',
  mounted () {
    core.index()
  },
  components: {
    UploadExcelComponent,
    LoaderProcess,
    // AgGridVue,
    UserLoad
  },
  data () {
    return {
      value: 0,
      max: 0,
      message: '',
      colorVariant: '',
      user: {
        basic_data: {
          nit: '54651412',
          name: 'test-',
          address: 'direccion test',
          mobile_no: '30054868751',
          country: 'Colombia',
          city: 'envigado',
          state: '',
          nroProperties: '',
          url: 'imgsCoProperties/base/empresa1.svg'
        }
      },
      searchData: '',
      password: '',
      repeat_password: '',
      repeat_email: '',
      dataMap: {},
      newDataMap: [],
      type: 'Contact',
      users: [],
      rowData: [],
      roles: {
        admin: {
          uid: localStorage.uid,
          dateOfStart: '30/07/2021'
        },
        cunselors: {},
        tax_auditor: {
          prueba: ''
        },
        public_accountant: {}
      },
      properties: {
        confirmation: ''
      },
      current_step: 1,
      max_step: 3,
      selected: 'Antioquia',
      options: [
        { item: 'Antioquia', name: 'Antioquia' },
        { item: 'Amazonas', name: 'Amazonas' },
        { item: 'Atlántico', name: 'Atlántico' },
        { item: 'Bolivar', name: 'Bolivar' },
        { item: 'La Guajira', name: 'La Guajira' }
      ],
      // The  Importation data component
      show: false,
      tableHeader: [],
      tableHeaderGrid: [],
      tableData: [],
      tableDataGrid: [],
      propertiesMap: [],
      propertiesIndexMap: {},
      lastCoPropertyRegister: '',
      hideExcel: true,
      spinner: false,
      ownersCollection: [],
      disabledBotonNext: false,
      paginationPageSize: null,
      modules: AllCommunityModules,
      listOwners: [],
      sala: '',
      consulta1: 'INSERT INTO `participantes` (`id`, `date_time`, `usuario`, `clave`, `nombre`, `porcentaje`, `correo`, `estado`, `apoderado`, `numero`, `hora_retiro`, `celular`, `registrar`, `puede_votar`, `qrcode_vota`) VALUES'
    }
  },
  computed: {
    filterCoProperties () {
      if (this.rowData.length > 0) {
        return this.rowData.filter(item => {
          return item.basic_data.name.toLowerCase().indexOf(this.searchData.toLowerCase()) > -1
        })
      } else {
        return []
      }
    },
    progress: function () {
      return Math.round(100 / this.max_step) * this.current_step
    }
  },
  beforeMount () {
    this.tableHeaderGrid = [
      { headerName: 'Name', field: 'nombre', sortable: true, filter: true },
      { headerName: 'Documento', field: 'documento', sortable: true, filter: true },
      { headerName: 'Phone', field: 'telefono', sortable: true, filter: true },
      { headerName: 'Email', field: 'correo', sortable: true, filter: true },
      { headerName: 'Profession', field: 'profesion', sortable: true, filter: true },
      { headerName: 'Apartment number', field: 'idPropiedad', sortable: true, filter: true }
    ]
    this.paginationPageSize = 10
  },
  methods: {
  /**
     * Metodo inicial donde se agrega primero la copropiedad con la informacion inicial y luego se agrega al super indice
     * al agregar el super indice al final, se ejecuta el metodo para registrar a los propietarios
     */
    async createSala () {
      const vm = this
      await db.collection('campus')
        .get()
        .then((querySnapshot) => {
          if (!querySnapshot.empty) {
            querySnapshot.forEach(doc => {
              if (doc.data() !== undefined) {
                vm.sala = doc.data().sala
              }
            })
          }
        })
      await axios({
        method: 'get',
        url: 'https://elconjunto.sistevot.com/admin/index.php/?option=com_fabrik&task=form.process&formid=3&gestionar_salas___nombre=' + this.sala,
        data: '',
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
        // handle success
          console.log(response)
          if (response.status === 200) {
            core.showSnackbar('success', 'sala creada con exito con el numero ' + vm.sala)
            console.log('sala creada con exito con el numero', vm.sala)
          } else {
            core.showSnackbar('danger', 'la sala no ha sido creada')
          }
        // app.contacts.push(contact)
        // app.resetForm()
        })
        .catch(function (response) {
        // handle error
          console.log(response)
        })
    },
    async handleOk1 () {
      const vm = this
      this.spinner = true
      this.colorVariant = 'primary'
      this.message = 'Validando informacion ...'
      this.consulta()
      const mysql = 'sala=r12&consulta=' + this.consulta1
      await axios({
        method: 'post',
        url: 'https://elconjunto.sistevot.com/admin/post.php',
        data: mysql,
        config: { headers: { 'Content-Type': 'multipart/form-data' } }
      })
        .then(function (response) {
        // handle success
          console.log(response)
          vm.max = 0
          vm.value = 0
          vm.spinner = false
          vm.message = ''
          vm.objectClean()
          vm.getData()
        // app.contacts.push(contact)
        // app.resetForm()
        })
        .catch(function (response) {
        // handle error
          console.log(response)
        })
    },
    consulta () {
      let usuario = ''
      let nombre = ''
      let coeficiente = 0
      let mail = '@'
      let phones = 0
      for (let i = 0; i < this.newDataMap.length; i++) {
        usuario = this.newDataMap[i].idProperty
        nombre = this.newDataMap[i].name
        coeficiente = this.newDataMap[i].coefProperty
        if (this.newDataMap[i].mails !== 0) {
          mail = this.newDataMap[i].mails
        } else {
          mail = '@'
        }
        if (this.newDataMap[i].phones !== 0 && this.newDataMap[i].phones !== undefined) {
          phones = this.newDataMap[i].phones
        } else {
          phones = 0
        }
        const prueba = "(NULL, '0000-00-00 00:00:00', " + usuario + ', NULL, "' + nombre + '",' + coeficiente + ', "' + mail + '"' + ", 'No Registrados', NULL, '0', '0000-00-00 00:00:00.000000', " + phones + ", '0', NULL, NULL)"
        if (i === 0) {
          this.consulta1 = this.consulta1 + ' ' + prueba
        } else {
          this.consulta1 = this.consulta1 + ', ' + prueba
        }
      }
      console.log(this.consulta1)
    },
    async handleOk () {
      this.spinner = true
      this.colorVariant = 'primary'
      this.message = 'Validando informacion ...'
      await db.collection('co-properties/').add({
        basic_data: {
          active: true,
          address: this.user.basic_data.address,
          city: this.user.basic_data.city,
          country: this.user.basic_data.country,
          mobile_no: this.user.basic_data.mobile_no,
          name: this.user.basic_data.name.toLowerCase(),
          nit: this.user.basic_data.nit,
          nroProperties: this.newDataMap.length + 1,
          state: this.selected,
          totalCoef: this.totalCoef,
          url: this.user.basic_data.url
        },
        roles: {
          admin: {
            uid: this.roles.admin.uid,
            dateOfStart: moment().format('MM/DD/YYYY')
          },
          cunselors: {},
          tax_auditor: {
            prueba: this.roles.tax_auditor.prueba
          },
          public_accountant: {}
        }
      })
        .then((docRef) => {
          this.lastCoPropertyRegister = docRef.id
        })
        .catch((error) => { console.error(`EL error es: ${error}`) }).finally()
      const objectUserLogin = JSON.parse(localStorage.getItem('userInformation'))
      const objectSuperIndex = {}
      objectSuperIndex[this.lastCoPropertyRegister] = {
        nit: this.user.basic_data.nit,
        name: this.user.basic_data.name.toLowerCase(),
        address: this.user.basic_data.address,
        city: this.user.basic_data.city,
        propertyIdReference: this.roles.admin.uid,
        administrator: objectUserLogin.name,
        emailProperty: objectUserLogin.email,
        docIdReferenceCoProperty: this.lastCoPropertyRegister,
        active: true
      }
      await db.collection('co-properties')
        .doc('co-properties')
        .set(objectSuperIndex, { merge: true })
        .then((doc) => {
        })
        .catch(error => {
          console.log('Error de actualizacion', error)
        })
      this.registerOwners()
    },
    async registerOwners () {
      this.max = this.newDataMap.length
      this.message = 'Validando informacion de los usuarios ...'
      for (let i = 0; i < this.newDataMap.length; i++) {
        this.value = i + 1
        const objectAdd = {}
        for (let j = 0; j < this.newDataMap[i].document.length; j++) {
          const documentToSearch = this.newDataMap[i].document[j]
          await db.collection('owner')
            .where('document', '==', documentToSearch)
            .get()
            .then(querySnapshot => {
              if (querySnapshot.empty) {
                const ownerObject = {
                  name: this.newDataMap[i].name[j] || 'Nombre no ingresado',
                  document: this.newDataMap[i].document[j] || '',
                  mail: this.newDataMap[i].mails !== null ? this.newDataMap[i].mails[j].toLowerCase() || '' : '',
                  phones: '000 000 00 00',
                  profession: '',
                  uid: ''
                }
                db.collection('owner')
                  .add(ownerObject)
                  .then(doc => {
                    ownerObject.docRef = doc.id
                    ownerObject.index = i
                    objectAdd[`${this.newDataMap[i].document[j]}`] = ownerObject
                  })
              } else {
                querySnapshot.forEach((doc) => {
                  const ownerObject = {
                    name: doc.data().name,
                    document: doc.data().document,
                    mail: doc.data().mail,
                    phones: doc.data().phones || '',
                    profession: doc.data().profession || '',
                    uid: doc.data().uid || '',
                    docRef: doc.id,
                    index: i
                  }
                  objectAdd[`${this.newDataMap[i].document[j]}`] = ownerObject
                })
              }
            })
        }
        this.listOwners.push(objectAdd)
      }
      this.registerProperties()
    },
    /**
     * Metodo que registra los propietarios, consta de que guarda un documento e identificamos que tpo de propiedad es
     * para guardarlo en un super indice
     */
    async registerProperties () {
      this.colorVariant = 'warning'
      this.max = this.newDataMap.length
      this.message = 'Relacionando las propiedades con los propietarios ...'
      const objectSuperIndex = {}
      const objectSuperIndexApartment = {}
      const objectSuperIndexUsefulRoom = {}
      const objectSuperIndexOffice = {}
      const objectSuperIndexParkingLot = {}
      const objectSuperIndexLocal = {}
      for (let i = 0; i < this.newDataMap.length; i++) {
        const propertiesForUser = {}
        this.value = i + 1
        // Agregando las propiedades
        if (this.newDataMap[i].idProperty === 'NO' ||
              this.newDataMap[i].idProperty === 'N/A' ||
              this.newDataMap[i].idProperty === 'NA' ||
              this.newDataMap[i].idProperty === '0' ||
              this.newDataMap[i].idProperty === 0) {
        } else {
          const objectProperty = {
            idProperty: this.newDataMap[i].idProperty.toString(),
            block: this.newDataMap[i].block || 'N/A',
            coef: this.newDataMap[i].coefProperty.toFixed(4),
            atype: 'Propiedad',
            docIdReferenceCoProperty: this.lastCoPropertyRegister,
            docIdOwners: this.listOwners[i]
          }
          await db.collection(`co-properties/${this.lastCoPropertyRegister}/properties`)
            .add(objectProperty)
            .then(doc => {
              objectProperty.id = doc.id
              objectSuperIndex[`${doc.id}`] = objectProperty
              objectSuperIndexApartment[`${doc.id}`] = objectProperty
              // Para empezar a organizar las propiedades de cada usuario
              propertiesForUser[`${doc.id}`] = objectProperty
            })
        }

        // Agregando los parqueaderos
        for (let j = 0; j < this.newDataMap[i].parkingLot.length; j++) {
          if (this.newDataMap[i].parkingLot[j] === 'NO' ||
              this.newDataMap[i].parkingLot[j] === 'N/A' ||
              this.newDataMap[i].parkingLot[j] === 'NA' ||
              this.newDataMap[i].parkingLot[j] === '0' ||
              this.newDataMap[i].parkingLot[j] === 0) {
          } else {
            let coefParkingLotParcial = 0
            if (this.newDataMap[i].parkingLot.length > 1) {
              coefParkingLotParcial = (this.newDataMap[i].coefParkingLot / this.newDataMap[i].parkingLot.length).toFixed(4)
            } else {
              coefParkingLotParcial = (this.newDataMap[i].coefParkingLot).toFixed(4)
            }
            const objectParkingLot = {
              idProperty: this.newDataMap[i].parkingLot[j],
              block: this.newDataMap[i].block || 'N/A',
              coef: coefParkingLotParcial,
              atype: 'Parqueadero',
              docIdReferenceCoProperty: this.lastCoPropertyRegister,
              docIdOwners: this.listOwners[i]
            }
            await db.collection(`co-properties/${this.lastCoPropertyRegister}/properties`)
              .add(objectParkingLot)
              .then(doc => {
                objectParkingLot.id = doc.id
                objectSuperIndex[`${doc.id}`] = objectParkingLot
                objectSuperIndexParkingLot[`${doc.id}`] = objectParkingLot
                propertiesForUser[`${doc.id}`] = objectParkingLot
              })
          }
        }
        // Agregando los cuartos utiles
        for (let j = 0; j < this.newDataMap[i].usefulRoom.length; j++) {
          if (this.newDataMap[i].usefulRoom[j] === 'NO' ||
              this.newDataMap[i].usefulRoom[j] === 'N/A' ||
              this.newDataMap[i].usefulRoom[j] === 'NA' ||
              this.newDataMap[i].usefulRoom[j] === '0' ||
              this.newDataMap[i].usefulRoom[j] === 0) {
          } else {
            let coefusefulRoomParcial = 0
            if (this.newDataMap[i].usefulRoom.length > 1) {
              coefusefulRoomParcial = (this.newDataMap[i].coefUsefulRoom / this.newDataMap[i].usefulRoom.length).toFixed(4)
            } else {
              coefusefulRoomParcial = (this.newDataMap[i].coefUsefulRoom).toFixed(4)
            }
            const objectuserfulRoom = {
              idProperty: this.newDataMap[i].usefulRoom[j],
              block: this.newDataMap[i].block || 'N/A',
              coef: coefusefulRoomParcial,
              atype: 'Cuartoutil',
              docIdReferenceCoProperty: this.lastCoPropertyRegister,
              docIdOwners: this.listOwners[i]
            }
            await db.collection(`co-properties/${this.lastCoPropertyRegister}/properties`)
              .add(objectuserfulRoom)
              .then(doc => {
                objectuserfulRoom.id = doc.id
                objectSuperIndex[`${doc.id}`] = objectuserfulRoom
                objectSuperIndexUsefulRoom[`${doc.id}`] = objectuserfulRoom
                propertiesForUser[`${doc.id}`] = objectuserfulRoom
              })
          }
        }
        // Agregando las oficinas
        for (let j = 0; j < this.newDataMap[i].office.length; j++) {
          if (this.newDataMap[i].office[j] === 'NO' ||
              this.newDataMap[i].office[j] === 'N/A' ||
              this.newDataMap[i].office[j] === 'NA' ||
              this.newDataMap[i].office[j] === '0' ||
              this.newDataMap[i].office[j] === 0) {
          } else {
            let coefofficeParcial = 0
            if (this.newDataMap[i].office.length > 1) {
              coefofficeParcial = (this.newDataMap[i].coefOffice / this.newDataMap[i].office.length).toFixed(4)
            } else {
              coefofficeParcial = (this.newDataMap[i].coefOffice).toFixed(4)
            }
            const objectOffice = {
              idProperty: this.newDataMap[i].office[j],
              block: this.newDataMap[i].block || 'N/A',
              coef: coefofficeParcial,
              atype: 'Oficina',
              docIdReferenceCoProperty: this.lastCoPropertyRegister,
              docIdOwners: this.listOwners[i]
            }
            await db.collection(`co-properties/${this.lastCoPropertyRegister}/properties`)
              .add(objectOffice)
              .then(doc => {
                objectOffice.id = doc.id
                objectSuperIndex[`${doc.id}`] = objectOffice
                objectSuperIndexOffice[`${doc.id}`] = objectOffice
                propertiesForUser[`${doc.id}`] = objectOffice
              })
          }
        }
        // Agregando los locales
        for (let j = 0; j < this.newDataMap[i].local.length; j++) {
          if (this.newDataMap[i].local[j] === 'NO' ||
              this.newDataMap[i].local[j] === 'N/A' ||
              this.newDataMap[i].local[j] === 'NA' ||
              this.newDataMap[i].local[j] === '0' ||
              this.newDataMap[i].local[j] === 0) {
          } else {
            let coefofficeParcial = 0
            if (this.newDataMap[i].local.length > 1) {
              coefofficeParcial = (this.newDataMap[i].coefLocal / this.newDataMap[i].local.length).toFixed(4)
            } else {
              coefofficeParcial = (this.newDataMap[i].coefLocal).toFixed(4)
            }
            const objectLocal = {
              idProperty: this.newDataMap[i].local[j],
              block: this.newDataMap[i].block || 'N/A',
              coef: coefofficeParcial,
              atype: 'Local',
              docIdReferenceCoProperty: this.lastCoPropertyRegister,
              docIdOwners: this.listOwners[i]
            }
            await db.collection(`co-properties/${this.lastCoPropertyRegister}/properties`)
              .add(objectLocal)
              .then(doc => {
                objectLocal.id = doc.id
                objectSuperIndex[`${doc.id}`] = objectLocal
                objectSuperIndexLocal[`${doc.id}`] = objectLocal
                propertiesForUser[`${doc.id}`] = objectLocal
              })
          }
        }
        // Agregando informacion de los usuarios
        for (let j = 0; j < (Object.values(this.listOwners[i])).length; j++) {
          const dataUser = Object.values(this.listOwners[i])[j]
          const object = {}
          object.dataUser = dataUser
          object.properties = propertiesForUser
          await db.collection(`co-properties/${this.lastCoPropertyRegister}/ownerss`).add(object)
        }
      }
      this.max = 0
      this.value = 0
      this.spinner = true
      this.message = 'Vinculando propiedades a la copropiedad, mantenga esta ventana abierta...'
      await db.collection(`co-properties/${this.lastCoPropertyRegister}/properties`)
        .doc('propiedades')
        .set(objectSuperIndex, { merge: true })
        .catch(error => {
          console.log('Error de actualizacion', error)
        })
      await db.collection(`co-properties/${this.lastCoPropertyRegister}/properties`)
        .doc('listado-apartamentos')
        .set(objectSuperIndexApartment, { merge: true })
        .catch(error => {
          console.log('Error de actualizacion', error)
        })
      await db.collection(`co-properties/${this.lastCoPropertyRegister}/properties`)
        .doc('listado-parqueadero')
        .set(objectSuperIndexParkingLot, { merge: true })
        .catch(error => {
          console.log('Error de actualizacion', error)
        })
      await db.collection(`co-properties/${this.lastCoPropertyRegister}/properties`)
        .doc('listado-cuartoutil')
        .set(objectSuperIndexUsefulRoom, { merge: true })
        .catch(error => {
          console.log('Error de actualizacion', error)
        })
      await db.collection(`co-properties/${this.lastCoPropertyRegister}/properties`)
        .doc('listado-oficina')
        .set(objectSuperIndexOffice, { merge: true })
        .catch(error => {
          console.log('Error de actualizacion', error)
        })
      await db.collection(`co-properties/${this.lastCoPropertyRegister}/properties`)
        .doc('listado-locales')
        .set(objectSuperIndexLocal, { merge: true })
        .catch(error => {
          console.log('Error de actualizacion', error)
        })
      // DE ESTA FORMA TENEMOS EL TOTAL DE MEGABYTES APROXIMADAMENTE CON UN DESFACE POR ENCIMA POR EJEMPLO 1.29MB EN JS Y 1'127.825 BYTES CON 1500 REGISTROS
      // const size = new TextEncoder().encode(JSON.stringify(objectSuperIndex)).length
      // const kiloBytes = size / 1024
      // const megaBytes = kiloBytes / 1024
      // console.log(megaBytes)
      this.max = 0
      this.value = 0
      this.spinner = false
      this.message = ''
      this.registerPropertyToOwner(objectSuperIndex)
    },
    async registerPropertyToOwner (data) {
      // console.log(data)
      this.value = 0
      this.colorVariant = 'primary'
      this.spinner = true
      this.message = 'Vinculando propiedades a los propietarios, un momento más por favor...'
      const arrayData = Object.values(data)
      this.max = arrayData.length
      for (let i = 0; i < arrayData.length; i++) {
        this.value++
        const owner = Object.values(arrayData[i].docIdOwners)
        for (let j = 0; j < owner.length; j++) {
          const objectAssing = {
            properties: {}
          }
          const objectAdd = {
            atype: arrayData[i].atype,
            block: arrayData[i].block,
            coef: arrayData[i].coef,
            docIdReferenceCoProperty: arrayData[i].docIdReferenceCoProperty,
            nameCoProperty: this.user.basic_data.name.toLowerCase(),
            id: arrayData[i].id,
            idProperty: arrayData[i].idProperty
          }
          objectAssing.properties[`${arrayData[i].id}`] = objectAdd
          console.log(objectAssing)
          console.log(owner[j].docRef)
          await db.collection('owner').doc(owner[j].docRef).set(objectAssing, { merge: true })
        }
      }
      this.max = 0
      this.value = 0
      this.spinner = false
      this.message = ''
      this.objectClean()
      this.getData()
    },
    closeMyModalAnCleanData () {
      this.current_step = 1
      this.tableData = []
      this.properties.confirmation = ''
      this.tableDataGrid = []
      this.$refs['my-modal'].hide()
    },
    redirectDashboardCoProperty (item) {
      this.$router.push({ path: `/coproperty-dashboard/${item.id}/` })
    },
    editProperty (item) {
      this.$router.push({ name: 'dashboardAdmin.editCoProperty', params: { idProperty: item.id } })
    },
    showModal () {
      this.$refs['my-modal'].show()
    },
    objectClean () {
      this.user.basic_data.nit = ''
      this.user.basic_data.name = ''
      this.user.basic_data.address = ''
      this.user.basic_data.mobile_no = ''
      this.user.basic_data.country = ''
      this.user.basic_data.state = ''
      this.user.basic_data.city = ''
      this.user.basic_data.nroProperties = ''
      this.properties.text = ''
      this.roles.admin.uid = ''
      this.roles.tax_auditor.prueba = ''
      this.current_step = 1
      this.propertiesMap = []
      this.tableHeader = []
      this.tableData = []
      this.newDataMap = []
      this.disabledBotonNext = false
      this.tableDataGrid = []
      this.lastCoPropertyRegister = ''
      this.properties.confirmation = ''
      this.propertiesIndexMap = {}
      this.closeMyModalAnCleanData()
    },
    onClickNext (step) {
      if (step === 1) {
        this.$refs.obserValidation.validate().then((result) => {
          if (result) {
            this.current_step++
          }
        })
      } else {
        if (step === 2) {
          if (this.tableData.length > 0) {
            this.current_step++
          } else {
            core.showSnackbar('success', 'It is necessary to load information.')
          }
        } else {
          this.current_step++
        }
      }
    },
    onClickBack: function () {
      this.current_step--
      this.hideExcel = true
      this.tableHeader = []
      this.tableData = []
      this.disabledBotonNext = true
    },
    onClickFirst: function () {
      this.current_step = 1
    },
    async getData () {
      try {
        await db.collection('co-properties')
          .where('roles.admin.uid', '==', localStorage.uid)
          .orderBy('basic_data.name')
          .get().then((querySnapshot) => {
            var array = []
            querySnapshot.forEach((doc) => {
              const data = {}
              data.basic_data = doc.data().basic_data
              data.roles = doc.data().roles
              data.id = doc.id
              ref.child(`${data.basic_data.url}`).getDownloadURL()
                .then((url) => {
                  data.photoUrl = url
                  array.push(data)
                })
                .catch((error) => {
                  core.showSnackbar('success', 'Error al cargar la imagen de storage, puede que no se haya creado')
                  console.log('Error:' + error.message)
                  data.photoUrl = ''
                  array.push(data)
                })
            })
            this.rowData = array
          })
      } catch (error) {
        core.showSnackbar('error', 'Ha ocurrido un error inesperado  por favor indicar error de codio COOWNERSHIP-800')
      }
    },
    onShown () {
      // Focus the cancel button when the overlay is showing
      this.$refs.cancel.focus()
    },
    // Metodos de
    onHidden () {
      // Focus the show button when the overlay is removed
      this.$refs.show.focus()
    },
    beforeUpload (file) {
      const isLt1M = file.size / 1024 / 1024 < 1
      if (isLt1M) {
        return true
      }
      this.$message({
        message: 'Please do not upload files larger than 1m in size.',
        type: 'warning'
      })
      return false
    },
    handleSuccess ({ results, header }) {
      if (results.length > 0 && header.length > 0) {
        core.showSnackbar('success', 'Se ha cargado un excel con informacion')
      } else {
        core.showSnackbar('error', 'El excel seleccionado parece no corresponder al formato solicitado o esta vacio')
      }
      this.disabledBotonNext = true
      this.tableData = results
      this.tableDataGrid = results
      this.tableHeader = header
    },
    saveDataMap ({ data, totalCoef }) {
      this.newDataMap = data
      this.totalCoef = totalCoef
      this.disabledBotonNext = false
    },
    hideExcelField (showField) {
      this.hideExcel = showField
      if (showField === false) {
        this.disabledBotonNext = true
        this.newDataMap = []
      }
    }
  },
  firestore () {
    this.getData()
  }
}
</script>
<style>
.card-style {
  width: 90%;
  margin: 0 auto;
  }
  .pointer-card{
  cursor: pointer;
  }
</style>
